import Image from 'next/image'

const LogoTextBlue = () => (
  <Image
    height={100}
    width={250}
    style={{ alignContent: "start" }}
    src="/images/brand/servicegeek-long.svg"
    alt="ServiceGeek"
  />
)

export default LogoTextBlue
